body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: gothic-bold;
    src: url("centurygothic_bold.ttf");
}

@font-face {
    font-family: gothic;
    src: url("centurygothic.ttf");
}

.title {
    font-family: gothic-bold;
    font-size: 24px;
}

.explain {
    text-align: left;
    color: #6E6E6E;
    font-size: 14px;
    max-width: 300px;

}

.grow {
    flex-grow: 0.4;
}

.text-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.callout {
    margin-top: 10px;
    font-family: gothic-bold;
    font-size: 30px;
    max-width: 300px;
    justify-content: flex-start;
    text-align: left;
    margin-bottom: 10px;
}

.button-form {
    flex-shrink: 0;
}



.input-form {
    display: flex;
    width: 300px;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
}

.main-content {
    width: 100vw;
    height: 90vh;
    flex: 1;
    display: flex;
    flex-direction: row;
    background-image: url('/public/backdrop.png');
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: flex-start;
    padding: 45px;
    overflow: hidden;

}

.left-panel {
    display: flex;
    flex-grow: 0.8;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100vh;
    width: 50vw;

}

.right-panel {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 50vw;
    background-size: contain;
    background-repeat: no-repeat;

    flex-shrink: 1;
}

.player-cont {

}

